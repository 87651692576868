import { Component } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import dayjs from "dayjs";
import { DatePicker } from "antd";

import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/footer";

import Spinner from "../../../components/spinner";

import shurjopay from "../../../assets/shurjopay.webp";

import "./appointment.scss";

export default class ScheduleAppointment extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      user: jwtDecode(localStorage.getItem("token")),
      date: "",
      time: this.getHours()[0].value,
    };

    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    if (this.state.user.package === "Free")
      return (window.location.href = "/packages");
  }

  getHours() {
    const hours = [];
    const startTime = dayjs().hour(10).minute(0);
    const endTime = dayjs().hour(22).minute(0);

    for (
      let time = startTime;
      time.isBefore(endTime) || time.isSame(endTime);
      time = time.add(1, "hour")
    ) {
      hours.push({ label: time.format("h A"), value: time.format("HH:mm") });
    }

    return hours;
  }

  async submit(event) {
    event.preventDefault();

    this.setState({ loading: true });

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/booking`,
        {
          type: "schedule",
          date: this.state.date,
          time: this.state.time,
        },
        config
      );

      if (data.success) window.location.href = data.url;
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className="appointment">
          <h1>Schedule an Appointment with us</h1>

          <div className="container">
            {this.state.loading ? (
              <Spinner></Spinner>
            ) : (
              <div>
                <form className="booking-form">
                  <div className="mb-3">
                    <label className="form-label">Select Date</label>
                    <DatePicker
                      disabledDate={(current) =>
                        current && current < dayjs().endOf("day")
                      }
                      required={true}
                      onChange={(date, dateString) =>
                        this.setState({ date: dateString })
                      }
                      className="w-100"
                    />
                  </div>

                  {this.state.date === "" ? (
                    <div className="danger">
                      Please select a date to see available slots
                    </div>
                  ) : (
                    <div>
                      <label className="form-label">Select Time</label>
                      <select
                        className="form-control mb-3"
                        defaultValue={this.state.time}
                        onChange={(event) =>
                          this.setState({ time: event.target.value })
                        }
                      >
                        {this.getHours().map((s, i) => (
                          <option value={s.value} key={i}>
                            {s.label}
                          </option>
                        ))}
                      </select>

                      <div className="payment my-3 py-2">
                        <div
                          className="d-flex justify-content-between align-items-center px-3 py-2"
                          style={{ background: "#f3f3f3" }}
                        >
                          <p style={{ fontWeight: 600 }} className="my-2">
                            Shurjopay
                          </p>
                          <img
                            src={shurjopay}
                            alt="shurjopay"
                            style={{
                              maxWidth: "200px",
                              margin: "0 15px",
                              maxHeight: "50px",
                            }}
                          />
                        </div>
                        <div className="bg-light px-3 pb-2 pt-3">
                          <p>Pay securely using ShurjoPay</p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between my-4">
                        <h3>Total</h3>
                        <h3>2,500 BDT</h3>
                      </div>

                      <div className="d-flex justify-content-end">
                        <button
                          className="button w-full"
                          type="submit"
                          onClick={this.submit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            )}
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}
