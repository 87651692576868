import { Component } from "react";
import { jwtDecode } from "jwt-decode";
import { Navigate } from "react-router-dom";

export default function Protected(ComposedComponent) {
  class Protected extends Component {
    state = {
      isAuthenticated: localStorage.getItem("token") ? true : false,
    };

    componentDidMount() {
      try {
        const decoded = jwtDecode(localStorage.getItem("token"));

        if (Date.now() >= decoded.exp * 1000) {
          this.setState({ isAuthenticated: false });
          localStorage.removeItem("token");
        } else {
          this.setState({ isAuthenticated: true });
        }
      } catch (error) {
        this.setState({ isAuthenticated: false });
        localStorage.removeItem("token");
      }

      console.log(this.props);
    }

    render() {
      return this.state.isAuthenticated ? (
        <ComposedComponent {...this.props}></ComposedComponent>
      ) : (
        <Navigate to="/sign-up?method=login"></Navigate>
      );
    }
  }

  return <Protected></Protected>;
}
