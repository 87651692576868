import { Component } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  ConversationList,
  Conversation,
} from "@chatscope/chat-ui-kit-react";
import io from "socket.io-client";

import DashSidebar from "../../../components/dashboard/sidebar";
import Spinner from "../../../components/spinner";

import "./queries.scss";

import notificationSound from "../../../assets/notification.mp3";

let socket;

export default class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      user: jwtDecode(localStorage.getItem("token")),
      chatData: [],
      group: [],
      chat: [],
      socketConnected: false,
      query:
        Object.fromEntries(new URLSearchParams(window.location.search)) || null,
    };

    this.sendMessage = this.sendMessage.bind(this);

    if (this.state.user.package === "Free")
      return (window.location.href = "/packages");

    document.title = "Dashboard - Messages";
  }

  async componentDidMount() {
    socket = io(process.env.REACT_APP_BACKEND_URL);
    socket.emit("setup", this.state.user.id);
    socket.on("connection", () => this.setState({ socketConnected: true }));

    socket.on("message received", (message) => {
      this.setState((prevState) => ({ chat: [...prevState.chat, message] }));

      let audio = new Audio(notificationSound);
      audio.volume = 0.4;
      audio.play();
    });

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let url = this.state.query.id
        ? `${process.env.REACT_APP_BACKEND_URL}/api/chat/get?type=message&id=${this.state.query.id}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/chat/get?type=message`;

      let { data } = await axios.get(url, config);
      this.setState({ loading: false, group: data.chats, chat: data.message });

      socket.emit("join chat", data.message[0].chatID);
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }

  componentWillUnmount() {
    if (socket) {
      socket.off("message received");
    }
  }

  async sendMessage(text) {
    if (!text || text === "") return;

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/chat/send?type=message&id=${this.state.query.id}`,
        { content: text },
        config
      );

      if (data.success) {
        this.setState((prevState) => ({
          chat: [...prevState.chat, data.data],
        }));
        socket.emit("new message", data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="dashboard">
        <div className="sidebar">
          <DashSidebar></DashSidebar>
        </div>
        <div className="contents">
          <h1>Messages</h1>

          {this.state.loading ? (
            <Spinner></Spinner>
          ) : (
            <div className="d-flex flex-row my-3">
              <div className="w-25">
                <ConversationList>
                  {this.state.group.map((g, i) => (
                    <Conversation
                      info={
                        g.lastMessage === null
                          ? "Tap to send message"
                          : g.lastMessage
                      }
                      key={i}
                      name={g.name}
                      onClick={() =>
                        (window.location.href = `/dashboard/messages?id=${g.id}`)
                      }
                    >
                      <Avatar name={g.name} src={g.avatar} />
                    </Conversation>
                  ))}
                </ConversationList>
              </div>
              <div className="inbox p-4 pb-0 flex-grow-1">
                <div className="chat">
                  <div className="chat">
                    <MainContainer>
                      <ChatContainer
                        style={{
                          borderRadius: "5px",
                          height: "678px",
                          padding: "20px 0",
                        }}
                      >
                        <MessageList>
                          {this.state.query.id ? (
                            this.state.chat?.map((message, index) => (
                              <Message
                                model={{
                                  message: message.content,
                                  direction:
                                    this.state.user.id === message.sender
                                      ? "outgoing"
                                      : "incoming",
                                }}
                                key={index}
                              >
                                <Avatar
                                  src={
                                    this.state.user.id === message.sender
                                      ? this.state.user.avatar
                                      : this.state.group.filter(
                                          (g) => g.id === message.sender
                                        )[0].avatar
                                  }
                                ></Avatar>
                              </Message>
                            ))
                          ) : (
                            <MessageList.Content
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                height: "100%",
                                textAlign: "center",
                                fontSize: "1.2em",
                                color: "black",
                              }}
                            >
                              Tap a user to view messages
                            </MessageList.Content>
                          )}
                        </MessageList>

                        <MessageInput
                          disabled={this.state.query.id ? false : true}
                          placeholder="Type your message here"
                          onSend={(text) => this.sendMessage(text)}
                        />
                      </ChatContainer>
                    </MainContainer>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
