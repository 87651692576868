import { Component } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.png";

import "./navbar.css";

export default class Navbar extends Component {
  render() {
    return (
      <div className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container d-flex align-items-center">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="logo" height="30px" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a href="/about-us" className="nav-link">
                  About Us
                </a>
              </li>

              <li className="nav-item d">
                <a
                  href="#"
                  className="nav-link d-btn"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Solutions
                </a>
                <div className="d-content shadow">
                  <div className="row container mx-auto">
                    <div className="col-md-4">
                      <div className="drop-link">
                        <h2>Ask Your Queries</h2>
                        <p>
                          Submit your legal question for a professional to
                          answer.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="drop-link">
                        <h2>Find Lawyer</h2>
                        <p>
                          Find a lawyer based on their practice area and
                          location that best suits your needs.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="drop-link">
                        <h2>Harassment/Abuse Queries</h2>
                        <p>
                          Make inquiries pertaining sensitive laws, such as
                          those that deal with sexual offenses, harassment and
                          etc.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="drop-link">
                        <h2>Contract Drafting</h2>
                        <p>
                          Get professionals to custom-write your legal
                          documentations.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="drop-link">
                        <h2>Know Your Rights</h2>
                        <p>
                          Watch educational videos about national laws and
                          regulations to better understand your rights as a
                          citizen.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="drop-link">
                        <h2>Legalx Library</h2>
                        <p>
                          A legal library that provides access to guidelines
                          established in earlier cases would be useful to both
                          common citizens and lawyers.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="nav-item">
                <a href="/packages" className="nav-link">
                  Pricing
                </a>
              </li>
            </ul>

            {localStorage.getItem("token") ? (
              <a className="navbar-button" href="/dashboard">
                My account
              </a>
            ) : (
              <span className="navbar-text ml-auto">
                <a className="navbar-button" href="/sign-up?method=login">
                  Login
                </a>
                <a className="navbar-button" href="/sign-up?method=register">
                  Register
                </a>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}
