import React, { Component } from "react";
import { jwtDecode } from "jwt-decode";

import DashSidebar from "../../components/dashboard/sidebar";

import "./dashboard.scss";

import service1 from "../../assets/service1.webp";
import service2 from "../../assets/service2.webp";
import service3 from "../../assets/service3.webp";
import service4 from "../../assets/service4.webp";
import service5 from "../../assets/service5.webp";
import service6 from "../../assets/service6.webp";

export class Dashboard extends Component {
  constructor() {
    super();

    this.state = {
      user: jwtDecode(localStorage.getItem("token")),
    };

    document.title = "Dashboard - LegalX";
  }

  render() {
    return (
      <div className="dashboard">
        <div className="sidebar">
          <DashSidebar></DashSidebar>
        </div>
        <div className="contents">
          <h2 className="mt-4">
            Hello, {this.state.user.name}! Let's get started.
          </h2>

          <div className="services">
            <div className="service queries">
              <div>
                <h3>Ask your queries</h3>
                <button
                  onClick={() => (window.location.href = "/dashboard/queries")}
                  className="service-btn"
                >
                  Ask now
                </button>
              </div>
              <div>
                <img
                  src={service1}
                  alt="Ask your queries"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="service lawyer">
              <div>
                <h3>Find Lawyer</h3>
                <button
                  onClick={() => (window.location.href = "/lawyers")}
                  className="service-btn"
                >
                  Find now
                </button>
              </div>
              <div>
                <img src={service2} alt="Find Lawyer" className="img-fluid" />
              </div>
            </div>

            <div className="break"></div>

            <div className="service help">
              <div>
                <h3>Book an appointment with us</h3>
                <button
                  className="service-btn"
                  onClick={() =>
                    (window.location.href = "/dashboard/book-appointment")
                  }
                >
                  Book Now
                </button>
              </div>
              <div>
                <img
                  src={service3}
                  alt="Book an appointment with us"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="service draft">
              <div>
                <h3>Get Drafted</h3>
                <button
                  className="service-btn"
                  onClick={() =>
                    (window.location.href = "https://getdrafted.store")
                  }
                >
                  Draft Now
                </button>
              </div>
              <div>
                <img
                  src={service4}
                  alt="Ask your queries"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="break"></div>

            <div className="service know">
              <div>
                <h3>Know your rights</h3>
                <button
                  className="service-btn"
                  onClick={() =>
                    (window.location.href = "/dashboard/know-your-rights")
                  }
                >
                  Know Now
                </button>
              </div>
              <div>
                <img
                  src={service5}
                  alt="Know your rights"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="service library">
              <div>
                <h3>LegalX Library</h3>
                <button
                  className="service-btn"
                  onClick={() => (window.location.href = "/dashboard/library")}
                >
                  Ask now
                </button>
              </div>
              <div>
                <img
                  src={service6}
                  alt="LegalX Library"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
