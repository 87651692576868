import { Component } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dateFormat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faLocationArrow,
  faClock,
  faTag,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";

import DashSidebar from "../../../components/dashboard/sidebar";
import Spinner from "../../../components/spinner";

import "./bookings.scss";

export default class Bookmarks extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      user: jwtDecode(localStorage.getItem("token")),
      bookmarks: [],
    };

    document.title = "My Bookmarks - LegalX";
  }

  async componentDidMount() {
    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/bookmark`,
        config
      );

      this.setState({ loading: false, bookmarks: data.lawyers });
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }

  render() {
    return (
      <div className="dashboard">
        <div className="sidebar">
          <DashSidebar></DashSidebar>
        </div>
        <div className="contents">
          <h1 className="my-4">My Bookmarks</h1>

          {this.state.loading ? (
            <Spinner></Spinner>
          ) : (
            <div>
              {this.state.bookmarks.map((profile, index) => (
                <div className="lawyer-profile" key={index}>
                  <div className="row">
                    <div className="col-md-8 col-sm-12">
                      <div className="d-flex flex-row gap-4">
                        <img
                          className="img-fluid"
                          src={profile.picture}
                          alt={profile.name}
                        ></img>
                        <div className="lawyer-info">
                          <h4 className="d-flex align-items-start">
                            {profile.name}
                            <span className="verified ms-2">
                              {profile.verified ? (
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  size="xs"
                                ></FontAwesomeIcon>
                              ) : null}
                            </span>
                          </h4>
                          {profile.category.map((cat, index) => (
                            <h7 className="category" key={index}>
                              {cat.label}
                            </h7>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 lawyer-details">
                      <div>
                        <div className="d-flex align-items-center gap-3 mb-3">
                          <FontAwesomeIcon
                            icon={faLocationArrow}
                            size="lg"
                            className="color-"
                          />
                          <div className="details">
                            {profile.location.region}
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3 mb-3">
                          <FontAwesomeIcon icon={faClock} size="lg" />
                          <div className="details">
                            <span
                              className={
                                new Date().getDay() === 1 ? "bold" : ""
                              }
                            >
                              Mon
                            </span>
                            ,
                            <span
                              className={
                                new Date().getDay() === 2 ? "bold" : ""
                              }
                            >
                              Tue
                            </span>
                            ,
                            <span
                              className={
                                new Date().getDay() === 3 ? "bold" : ""
                              }
                            >
                              Wed
                            </span>
                            ,
                            <span
                              className={
                                new Date().getDay() === 4 ? "bold" : ""
                              }
                            >
                              Thu
                            </span>
                            ,
                            <span
                              className={
                                new Date().getDay() === 5 ? "bold" : ""
                              }
                            >
                              Fri
                            </span>
                            ,
                            <span
                              className={
                                new Date().getDay() === 6 ? "bold" : ""
                              }
                            >
                              Sat
                            </span>
                            ,
                            <span
                              className={
                                new Date().getDay() === 0 ? "bold" : ""
                              }
                            >
                              Sun
                            </span>
                            ,
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3 mb-3">
                          <FontAwesomeIcon icon={faTag} size="lg" />
                          <div className="details">
                            Starting from{" "}
                            <span className="bold">
                              &#2547; {profile.pricing[0]}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3 mb-3">
                          <FontAwesomeIcon icon={faCalendar} size="lg" />
                          <div className="details available">
                            Available Today
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-row gap-2 items-center">
                        <button
                          className="button"
                          onClick={() =>
                            (window.location.href = `/lawyer/${profile._id}`)
                          }
                        >
                          View Full Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
