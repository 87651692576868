import React, { Component } from "react";
import { jwtDecode } from "jwt-decode";
import Spinner from "../../../components/spinner";
import axios from "axios";

import Navbar from "../../../components/navbar/navbar";
import Footer from "../../../components/footer/footer";

import instantBooking from "../../../assets/instantbooking.jpg";
import scheduleBooking from "../../../assets/schedulebooking.jpg";

import "./appointment.scss";

export class Appointment extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      user: jwtDecode(localStorage.getItem("token")),
    };

    this.instantBooking = this.instantBooking.bind(this);
  }

  async componentDidMount() {
    if (this.state.user.package === "Free")
      return (window.location.href = "/packages");
  }

  async instantBooking() {
    this.setState({ loading: true });

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/booking`,
        { type: "instant" },
        config
      );

      if (data.success) window.location.href = data.url;
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className="appointment">
          <div className="container">
            <h1>Book an appointment with us</h1>

            {this.state.loading ? (
              <Spinner></Spinner>
            ) : (
              <div className="row text-center">
                <div className="col-md-6">
                  <div className="booking">
                    <img
                      src={instantBooking}
                      alt="Instant Booking"
                      className="img-fluid"
                    />
                    <h2>
                      Instant Booking -{" "}
                      <span className="text-red" style={{ fontSize: "2.5rem" }}>
                        4,000 BDT
                      </span>
                    </h2>
                    <p>
                      Get expert advice on-demand with our secure, real-time
                      virtual meetings. Whether it's corporate law, compliance,
                      or contract issues, connect with a professional lawyer
                      anytime, anywhere to resolve your legal matters
                      efficiently.
                    </p>
                    <button
                      className="button-outline"
                      onClick={() => this.instantBooking()}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="booking">
                    <img
                      src={scheduleBooking}
                      alt="Schedule Booking"
                      className="img-fluid"
                    />
                    <h2>
                      Schedule Booking -{" "}
                      <span className="text-red" style={{ fontSize: "2.5rem" }}>
                        2,500 BDT
                      </span>
                    </h2>
                    <p>
                      Whoose your preferred service and time, and get connected
                      with a professional lawyer at your convenience. Whether
                      it’s for corporate law, contracts, or compliance, booking
                      a meeting is fast, simple, and tailored to your needs.
                    </p>
                    <button
                      className="button-outline"
                      onClick={() =>
                        (window.location.href =
                          "/dashboard/book-appointment/schedule")
                      }
                    >
                      Schedule Now
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Appointment;
