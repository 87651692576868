import { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Protected from "./controllers/Protected";

import Home from "./screens/home/home";
import AboutUs from "./screens/home/aboutUs";
import Package from "./screens/home/packages";
import Checkout from "./screens/home/checkout";
import OrderComplete from "./screens/home/orderComplete";

import Signup from "./screens/dashboard/signup";
import ForgotPassword from "./screens/dashboard/forgotPassword";
import ResetPassword from "./screens/dashboard/resetPassword";
import FindLawyers from "./screens/lawyers/find";
import Dashboard from "./screens/dashboard/dashboard";
import Logout from "./screens/dashboard/logout";
import LawyerProfile from "./screens/dashboard/lawyerProfile";
import ChatQueries from "./screens/dashboard/services/queries";
import Appointment from "./screens/dashboard/services/appointment";
import Library from "./screens/dashboard/services/library";
import Account from "./screens/dashboard/account";
import Lawyer from "./screens/lawyers/lawyer";
import Booking from "./screens/lawyers/booking";
import Rights from "./screens/dashboard/services/rights";
import MyBookings from "./screens/dashboard/services/bookings";
import pdfViewer from "./screens/dashboard/services/pdfViewer";
import Messages from "./screens/dashboard/services/messages";
import Bookmarks from "./screens/dashboard/services/bookmarks";
import ScheduleAppointment from "./screens/dashboard/services/scheduleAppointment";

import "./App.scss";

export default class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home></Home>}></Route>
            <Route exact path="/about-us" element={<AboutUs></AboutUs>}></Route>
            <Route exact path="/packages" element={<Package></Package>}></Route>
            <Route exact path="/sign-up" element={<Signup></Signup>}></Route>
            <Route
              exact
              path="/forgot-password"
              element={<ForgotPassword></ForgotPassword>}
            ></Route>
            <Route
              exact
              path="/reset-password/:token"
              element={<ResetPassword></ResetPassword>}
            ></Route>
            <Route
              exact
              path="/checkout"
              element={<Checkout></Checkout>}
            ></Route>
            <Route
              exact
              path="/order-complete"
              element={<OrderComplete></OrderComplete>}
            ></Route>

            <Route exact path="/lawyer/:id" element={Protected(Lawyer)}></Route>
            <Route
              exact
              path="/lawyer/booking"
              element={Protected(Booking)}
            ></Route>
            <Route
              exact
              path="/lawyers"
              element={Protected(FindLawyers)}
            ></Route>
            <Route
              exact
              path="/dashboard"
              element={Protected(Dashboard)}
            ></Route>
            <Route
              exact
              path="/dashboard/my-account"
              element={Protected(Account)}
            ></Route>
            <Route
              exact
              path="/dashboard/messages"
              element={Protected(Messages)}
            ></Route>
            <Route
              exact
              path="/dashboard/my-bookings"
              element={Protected(MyBookings)}
            ></Route>
            <Route
              exact
              path="/dashboard/bookmarks"
              element={Protected(Bookmarks)}
            ></Route>
            <Route
              exact
              path="/dashboard/lawyer-profile"
              element={Protected(LawyerProfile)}
            ></Route>
            <Route
              exact
              path="/dashboard/queries"
              element={Protected(ChatQueries)}
            ></Route>
            <Route
              exact
              path="/dashboard/book-appointment"
              element={Protected(Appointment)}
            ></Route>
            <Route
              exact
              path="/dashboard/book-appointment/schedule"
              element={Protected(ScheduleAppointment)}
            ></Route>
            <Route
              exact
              path="/dashboard/know-your-rights"
              element={Protected(Rights)}
            ></Route>
            <Route
              exact
              path="/dashboard/library"
              element={Protected(Library)}
            ></Route>
            <Route
              exact
              path="/dashboard/library/pdf"
              element={Protected(pdfViewer)}
            ></Route>
            <Route exact path="/logout" element={Protected(Logout)}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
