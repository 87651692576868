import { jwtDecode } from "jwt-decode";
import React, { Component } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";

import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import Spinner from "../../components/spinner";

import "./booking.scss";

export class Booking extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      user: jwtDecode(localStorage.getItem("token")),
      booking: JSON.parse(localStorage.getItem("booking")),
      message: "",
    };
  }

  async componentDidMount() {
    if (this.state.user.package === "Free")
      return (window.location.href = "/packages");

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/user`,
        config
      );

      if (data.success) this.setState({ loading: false, user: data.data });
    } catch (error) {
      console.log(error);
    }
  }

  async bookAppointment(event) {
    event.preventDefault();

    this.setState({ loading: true });

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/lawyer/book`,
        {
          message: this.state.message,
          date: this.state.booking.date,
          time: this.state.booking.time,
          lawyerID: this.state.booking.lawyerID,
        },
        config
      );

      if (data.success) {
        window.location.href = "/dashboard/my-bookings";
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }

  render() {
    return (
      <div>
        <Navbar></Navbar>
        <div className="container">
          <h3 className="py-5 mt-3">Booking Confirmation</h3>

          {this.state.loading ? (
            <Spinner></Spinner>
          ) : (
            <div className="booking-form">
              <div className="row gx-5">
                <div className="col-md-8">
                  <h5>Personal Details</h5>

                  <form>
                    <div className="row py-4">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">First Name</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            value={this.state.user.firstName}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Last Name</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            value={this.state.user.lastName}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Email Address</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            value={this.state.user.email}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Phone Number</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            value={this.state.user.phoneNumber}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Message</label>
                        <textarea
                          className="form-control"
                          value={this.state.message}
                          onChange={(event) =>
                            this.setState({ message: event.target.value })
                          }
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>

                      <div className="d-flex">
                        <button
                          className="button mt-2"
                          onClick={(event) => this.bookAppointment(event)}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-4">
                  <div className="booking-details">
                    <div className="lawyer-details">
                      <div
                        className="lawyer-info"
                        style={{
                          background: `linear-gradient(to top, rgba(35, 35, 37, 0.9) 0%, rgba(35, 35, 37, 0.55) 35%, rgba(22, 22, 23, 0.1) 60%, rgba(0, 0, 0, 0) 100%), url(${this.state.booking.picture})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          height: "240px",
                        }}
                      >
                        <h4 className="d-flex align-items-center">
                          {this.state.booking.name}
                          <span className="verified ms-2">
                            {this.state.booking.verified ? (
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                              ></FontAwesomeIcon>
                            ) : null}
                          </span>
                        </h4>
                        <p>{this.state.booking.address}</p>
                      </div>
                      <div className="booking">
                        <h5>
                          <FontAwesomeIcon
                            icon={faCalendarCheck}
                            size="1x"
                            className="me-2"
                          ></FontAwesomeIcon>{" "}
                          Booking
                        </h5>

                        <div className="item d-flex justify-content-between">
                          <p>Date</p>
                          <p>{this.state.booking.date}</p>
                        </div>

                        <div className="item d-flex justify-content-between">
                          <p>Time</p>
                          <p>{this.state.booking.time}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default Booking;
