import React, { Component } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { TimePicker, DatePicker } from "antd";
import { Markup } from "interweave";
import Select from "react-select";

import {
  faClipboard,
  faImage,
  faPaste,
  faClock,
} from "@fortawesome/free-regular-svg-icons";
import {
  faLocationDot,
  faCalendarCheck,
  faTag,
  faPhone,
  faEnvelope,
  faPenToSquare,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import DashSidebar from "../../components/dashboard/sidebar";
import Spinner from "../../components/spinner";

import "react-quill/dist/quill.snow.css";
import "./lawyerProfile.scss";

let format = "HH:mm";

let categories = [
  { value: "ACCIDENTS & INJURIES", label: "ACCIDENTS & INJURIES" },
  {
    value: "ADR. Arbitration & Mediation",
    label: "ADR. Arbitration & Mediation",
  },
  { value: "BANKRUPTCY & DEBT", label: "BANKRUPTCY & DEBT" },
  { value: "BUSINESS LAW", label: "BUSINESS LAW" },
  { value: "CIVIL & HUMAN RIGHTS", label: "CIVIL & HUMAN RIGHTS" },
  { value: "CONSUMER RIGHTS", label: "CONSUMER RIGHTS" },
  { value: "CRIMINAL DEFENSE", label: "CRIMINAL DEFENSE" },
  { value: "DISABILITY", label: "DISABILITY" },
  { value: "DIVORCE & FAMILY LAW", label: "DIVORCE & FAMILY LAW" },
  { value: "EMPLOYEES' RIGHTS", label: "EMPLOYEES' RIGHTS" },
  { value: "ESTATE PLANNING", label: "ESTATE PLANNING" },
  { value: "HEALTH CARE", label: "HEALTH CARE" },
  { value: "IMMIGRATION", label: "IMMIGRATION" },
  { value: "INSURANCE", label: "INSURANCE" },
  { value: "INTELLECTUAL PROPERTY", label: "INTELLECTUAL PROPERTY" },
  { value: "REAL ESTATE", label: "REAL ESTATE" },
];

export class LawyerProfile extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      user: jwtDecode(localStorage.getItem("token")),
      lawyerData: "",
      address: "",
      postcode: "",
      working: "",
      region: "Choose Region",
      userData: "",
      name: "",
      keywords: "",
      category: [],
      picture: "",
      description: "",
      phoneNumber: "",
      email: "",
      linkedin: "",
      minimumPricing: "",
      maximumPricing: "",
      education: [
        {
          id: 1,
          name: "",
          designation: "",
          start: "",
          end: "",
        },
      ],
      experience: [
        {
          id: 1,
          name: "",
          designation: "",
          start: "",
          end: "",
        },
      ],
      certificate: [{ id: 1, name: "", for: "", at: "" }],
      slots: {
        monday: { startTime: "", endTime: "", duration: "30", interval: "5" },
        tuesday: { startTime: "", endTime: "", duration: "30", interval: "5" },
        wednesday: {
          startTime: "",
          endTime: "",
          duration: "30",
          interval: "5",
        },
        thursday: { startTime: "", endTime: "", duration: "30", interval: "5" },
        friday: { startTime: "", endTime: "", duration: "30", interval: "5" },
        saturday: { startTime: "", endTime: "", duration: "30", interval: "5" },
        sunday: { startTime: "", endTime: "", duration: "30", interval: "5" },
      },
      booking: true,
      availableSlot: [],
      preview: false,
      autoFocus: "",
      previewActive: "overview",
      defaultAvailableSlots: [],
    };

    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    if (this.state.user.type !== "lawyer")
      return (window.location.href = "/dashboard");

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/lawyer/get`,
        config
      );

      if (data.success)
        this.setState({
          loading: false,
          lawyerData: data.lawyer,
          userData: data.user,
          name: data.lawyer.name,
          phoneNumber: data.lawyer.phoneNumber,
          email: data.lawyer.email,
          working: data.lawyer.working,
          postcode: data.lawyer.location.postcode,
          address: data.lawyer.location.address,
          region: data.lawyer.location.region,
          keywords: data.lawyer.keywords,
          education:
            data.lawyer.education.length === 0
              ? [
                  {
                    id: 1,
                    name: "",
                    designation: "",
                    start: "",
                    end: "",
                  },
                ]
              : data.lawyer.education,
          experience:
            data.lawyer.experience.length === 0
              ? [
                  {
                    id: 1,
                    name: "",
                    designation: "",
                    start: "",
                    end: "",
                  },
                ]
              : data.lawyer.experience,
          certificate:
            data.lawyer.certification.length === 0
              ? [{ id: 1, name: "", for: "", at: "" }]
              : data.lawyer.certification,
          category: data.lawyer.category,
          picture: data.lawyer.picture,
          description: data.lawyer.description,
          linkedin: data.lawyer.linkedin,
          minimumPricing: data.lawyer.pricing[0],
          maximumPricing: data.lawyer.pricing[1],
          slots: data.lawyer.appointments.slots,
          booking: data.lawyer.booking,
          defaultAvailableSlots: data.lawyer.appointments.notAvailable.map(
            (slots) => dayjs(slots)
          ),
        });
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }

  preview() {
    if (this.state.name === "") return alert("Please provide your name");
    else if (this.state.category === "Choose Category")
      return alert("Please provide your category");
    else if (this.state.address === "")
      return alert("Please provide your address");
    else if (this.state.working === "")
      return alert("Please provide where you are currently working");
    else if (this.state.postcode === "")
      return alert("Please provide your postcode");
    else if (this.state.region === "Choose Region")
      return alert("Please provide your region");
    else if (this.state.picture === "")
      return alert("Please provide your picture for listing");
    else if (this.state.description === "")
      return alert("Please provide a description for your listing");
    else if (this.state.minimumPricing === "")
      return alert("Please provide a minimum pricing for your listing");
    else if (this.state.maximumPricing === "")
      return alert("Please provide a maximum pricing for your listing");
    else if (
      this.state.education[0].name === "" ||
      this.state.education[0].designation === "" ||
      this.state.education[0].start === "" ||
      this.state.education[0].end === ""
    )
      return alert("Please provide at least one education information");
    else if (
      this.state.experience[0].name === "" ||
      this.state.experience[0].designation === "" ||
      this.state.experience[0].start === "" ||
      this.state.experience[0].end === ""
    )
      return alert("Please provide at least one experience information");
    else if (
      this.state.certificate[0].name === "" ||
      this.state.certificate[0].for === "" ||
      this.state.certificate[0].at === ""
    )
      return alert("Please provide at least one certificate information");
    else this.setState({ preview: true });
  }

  async submit() {
    this.setState({ loading: true });

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let formData = new FormData();

      formData.append("name", this.state.name);
      formData.append("category", JSON.stringify(this.state.category));
      formData.append("keywords", this.state.keywords);
      formData.append("address", this.state.address);
      formData.append("region", this.state.region);
      formData.append("working", this.state.working);
      formData.append("postcode", this.state.postcode);
      formData.append("picture", this.state.picture);
      formData.append("description", this.state.description);
      formData.append("education", JSON.stringify(this.state.education));
      formData.append("experience", JSON.stringify(this.state.experience));
      formData.append("certification", JSON.stringify(this.state.certificate));
      formData.append("minimumPricing", this.state.minimumPricing);
      formData.append("maximumPricing", this.state.maximumPricing);
      formData.append("booking", this.state.booking);
      formData.append("slots", JSON.stringify(this.state.slots));
      formData.append("notAvailable", this.state.availableSlot);

      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/lawyer/upload`,
        formData,
        config
      );

      if (data.success) {
        this.setState({ loading: false });
        window.location.href = "/dashboard/lawyer-profile";
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleEducationInputChange = (index, event) => {
    const { name, value } = event.target;
    const education = [...this.state.education];

    education[index][name] = value;

    this.setState({ education });
  };

  handleEducationAddFields = () => {
    this.setState((prevState) => ({
      education: [
        ...prevState.education,
        {
          id: prevState.education[prevState.education.length - 1].id + 1,
          name: "",
          designation: "",
          start: "",
          end: "",
        },
      ],
    }));
  };

  handleEducationRemoveFields = (index) => {
    const education = [...this.state.education];
    education.splice(index, 1);
    this.setState({ education });
  };

  handleExperienceInputChange = (index, event) => {
    const { name, value } = event.target;
    const experience = [...this.state.experience];

    experience[index][name] = value;

    this.setState({ experience });
  };

  handleExperienceAddFields = () => {
    this.setState((prevState) => ({
      experience: [
        ...prevState.experience,
        {
          id: prevState.experience[prevState.experience.length - 1].id + 1,
          name: "",
          designation: "",
          start: "",
          end: "",
        },
      ],
    }));
  };

  handleExperienceRemoveFields = (index) => {
    const experience = [...this.state.experience];
    experience.splice(index, 1);
    this.setState({ experience });
  };

  handleCertificateInputChange = (index, event) => {
    const { name, value } = event.target;
    const certificate = [...this.state.certificate];

    certificate[index][name] = value;

    this.setState({ certificate });
  };

  handleCertificateAddFields = () => {
    this.setState((prevState) => ({
      certificate: [
        ...prevState.certificate,
        {
          id: prevState.certificate[prevState.certificate.length - 1].id + 1,
          name: "",
          for: "",
          at: "",
        },
      ],
    }));
  };

  handleCertificateRemoveFields = (index) => {
    const certificate = [...this.state.certificate];
    certificate.splice(index, 1);
    this.setState({ certificate });
  };

  render() {
    return (
      <div className="dashboard">
        <div className="sidebar">
          <DashSidebar></DashSidebar>
        </div>

        {this.state.preview ? (
          <div className="contents">
            {this.state.loading ? (
              <Spinner></Spinner>
            ) : (
              <div className="preview">
                <h1 className="py-4">Review Lawyer Profile</h1>

                <div className="info">
                  <span className="fw-bold">Notice!</span> This is preview of
                  listing you've submitted, please confirm or edit your
                  submission using buttons at the end of that page.
                </div>

                <div className="profile p-5">
                  <h2>
                    {this.state.name}{" "}
                    <div className="d-flex align-items-center gap-2 my-2">
                      {this.state.category.map((cat) => (
                        <span className="category">{cat.label}</span>
                      ))}
                    </div>
                  </h2>
                  <p
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => (window.location.href = "#location")}
                  >
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      color="#888"
                      className="me-2"
                    ></FontAwesomeIcon>{" "}
                    {this.state.address}
                  </p>
                  <p>
                    Region: {this.state.region} | Postcode:{" "}
                    {this.state.postcode}
                  </p>

                  <p>Working for: {this.state.working}</p>

                  <ul className="nav">
                    <li>
                      <a
                        href="#overview"
                        className={
                          this.state.previewActive === "overview"
                            ? "active"
                            : ""
                        }
                      >
                        Overview
                      </a>
                    </li>
                    <li>
                      <a
                        href="#gallery"
                        className={
                          this.state.previewActive === "gallery" ? "active" : ""
                        }
                      >
                        Gallery
                      </a>
                    </li>
                  </ul>

                  <section id="overview">
                    <Markup content={this.state.description} />

                    <div className="mt-4 mb-2">
                      <h5 className="mb-3 border-bottom pb-3">Education</h5>
                      <div className="row">
                        {this.state.education.map((edu, index) => (
                          <div className="col-md-4" key={index}>
                            <h5>{edu.name}</h5>
                            <p>{edu.designation}</p>
                            <p>
                              {edu.start} - {edu.end}
                            </p>
                          </div>
                        ))}
                      </div>

                      <h5 className="mb-3 border-bottom pb-3">Experience</h5>
                      <div className="row">
                        {this.state.experience.map((exp, index) => (
                          <div className="col-md-4" key={index}>
                            <h5>{exp.name}</h5>
                            <p>{exp.designation}</p>
                            <p>
                              {exp.start} - {exp.end}
                            </p>
                          </div>
                        ))}
                      </div>

                      <h5 className="mb-3 border-bottom pb-3">Certificate</h5>
                      <div className="row">
                        {this.state.certificate.map((cert, index) => (
                          <div className="col-md-4" key={index}>
                            <h5>{cert.name}</h5>
                            <p>{cert.for}</p>
                            <p>{cert.at}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="mt-4 mb-2">
                      <h5 className="mb-2">Pricing</h5>
                      <div className="pricing-info">
                        <FontAwesomeIcon
                          icon={faTag}
                          size="1x"
                          className="pricing"
                        ></FontAwesomeIcon>
                        <span>
                          &#2547; {this.state.minimumPricing} - &#2547;{" "}
                          {this.state.maximumPricing}
                        </span>
                      </div>
                    </div>

                    <div className="overview-info">
                      <div className="contact">
                        <a href={"tel:" + this.state.phoneNumber}>
                          <FontAwesomeIcon
                            icon={faPhone}
                            size="1x"
                            className="me-2"
                          ></FontAwesomeIcon>{" "}
                          {this.state.phoneNumber}
                        </a>
                      </div>
                      <div className="contact">
                        <a href={"mailto:" + this.state.email}>
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            size="1x"
                            className="me-2"
                          ></FontAwesomeIcon>{" "}
                          {this.state.email}
                        </a>
                      </div>
                    </div>

                    <div className="mt-4 mb-2 opening">
                      <h3>
                        <FontAwesomeIcon
                          icon={faClock}
                          size="1x"
                          className="me-2"
                        ></FontAwesomeIcon>{" "}
                        Opening Hours
                      </h3>
                      <ul>
                        <li>
                          Monday{" "}
                          {this.state.slots.monday.startTime === "" ||
                          this.state.slots.monday.startTime === "00:00" ? (
                            <span>Closed</span>
                          ) : (
                            <span>
                              {this.state.slots.monday.startTime} -{" "}
                              {this.state.slots.monday.endTime}
                            </span>
                          )}
                        </li>
                        <li>
                          Tuesday{" "}
                          {this.state.slots.tuesday.startTime === "" ||
                          this.state.slots.tuesday.startTime === "00:00" ? (
                            <span>Closed</span>
                          ) : (
                            <span>
                              {this.state.slots.tuesday.startTime} -{" "}
                              {this.state.slots.tuesday.endTime}
                            </span>
                          )}
                        </li>
                        <li>
                          Wednesday{" "}
                          {this.state.slots.wednesday.startTime === "" ||
                          this.state.slots.wednesday.startTime === "00:00" ? (
                            <span>Closed</span>
                          ) : (
                            <span>
                              {this.state.slots.wednesday.startTime} -{" "}
                              {this.state.slots.wednesday.endTime}
                            </span>
                          )}
                        </li>
                        <li>
                          Thursday{" "}
                          {this.state.slots.thursday.startTime === "" ||
                          this.state.slots.thursday.startTime === "00:00" ? (
                            <span>Closed</span>
                          ) : (
                            <span>
                              {this.state.slots.thursday.startTime} -{" "}
                              {this.state.slots.thursday.endTime}
                            </span>
                          )}
                        </li>
                        <li>
                          Friday{" "}
                          {this.state.slots.friday.startTime === "" ||
                          this.state.slots.friday.startTime === "00:00" ? (
                            <span>Closed</span>
                          ) : (
                            <span>
                              {this.state.slots.friday.startTime} -{" "}
                              {this.state.slots.friday.endTime}
                            </span>
                          )}
                        </li>
                        <li>
                          Saturday{" "}
                          {this.state.slots.saturday.startTime === "" ||
                          this.state.slots.saturday.startTime === "00:00" ? (
                            <span>Closed</span>
                          ) : (
                            <span>
                              {this.state.slots.saturday.startTime} -{" "}
                              {this.state.slots.saturday.endTime}
                            </span>
                          )}
                        </li>
                        <li>
                          Sunday{" "}
                          {this.state.slots.sunday.startTime === "" ||
                          this.state.slots.sunday.startTime === "00:00" ? (
                            <span>Closed</span>
                          ) : (
                            <span>
                              {this.state.slots.sunday.startTime} -{" "}
                              {this.state.slots.sunday.endTime}
                            </span>
                          )}
                        </li>
                      </ul>
                    </div>
                  </section>

                  <section id="gallery">
                    <h3>Gallery</h3>

                    {this.state.picture.type ? (
                      <img
                        src={URL.createObjectURL(this.state.picture)}
                        className="img-fluid my-3"
                        alt="gallery"
                        width="400px"
                      />
                    ) : (
                      <img
                        src={this.state.picture}
                        className="img-fluid my-3"
                        alt="gallery"
                        width="400px"
                      />
                    )}
                  </section>
                </div>

                <div className="info">
                  Editing listing requires admin approval, your listing will be
                  unpublished if you Save Changes.
                </div>

                <div className="mt-4 submit">
                  <button
                    className="button"
                    onClick={() => this.setState({ preview: false })}
                  >
                    <FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon>{" "}
                    Edit Listing
                  </button>
                  <button className="button" onClick={() => this.submit()}>
                    <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Save
                    Changes
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="contents">
            {this.state.loading ? (
              <Spinner></Spinner>
            ) : (
              <div className="my-3">
                <h1>Add Lawyer Profile</h1>

                <form onSubmit={(event) => this.preview(event)}>
                  <div className="profile">
                    <h3>
                      <FontAwesomeIcon
                        icon={faClipboard}
                        size="1x"
                        className="me-4"
                      ></FontAwesomeIcon>
                      Basic Information
                    </h3>

                    <div className="lawyer-form">
                      <div className="row">
                        <div className="col-md-6">
                          <label>Lawyer Name</label>
                          <input
                            type="text"
                            value={this.state.name}
                            required
                            onChange={(event) =>
                              this.setState({ name: event.target.value })
                            }
                            className="form-control mb-3"
                            placeholder=""
                          />
                        </div>

                        <div className="col-md-6">
                          <label>Currently working for</label>
                          <input
                            type="text"
                            value={this.state.working}
                            required
                            onChange={(event) =>
                              this.setState({ working: event.target.value })
                            }
                            className="form-control mb-3"
                            placeholder=""
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6" style={{ zIndex: "2" }}>
                          <label>Category</label>
                          <Select
                            options={categories}
                            defaultValue={this.state.category}
                            required
                            isMulti
                            onChange={(value) =>
                              this.setState({ category: value })
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Keywords</label>
                          <input
                            type="text"
                            value={this.state.keywords}
                            onChange={(event) =>
                              this.setState({ keywords: event.target.value })
                            }
                            className="form-control mb-3"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile">
                    <h3>
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        size="1x"
                        className="me-4"
                      ></FontAwesomeIcon>
                      Location
                    </h3>

                    <div className="lawyer-form">
                      <div className="row">
                        <div className="col-md-6">
                          <label>Address</label>
                          <input
                            type="text"
                            value={this.state.address}
                            required
                            onChange={(event) =>
                              this.setState({ address: event.target.value })
                            }
                            className="form-control mb-3"
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Region</label>
                          <select
                            value={this.state.region}
                            required
                            onChange={(event) =>
                              this.setState({ region: event.target.value })
                            }
                            className="form-control mb-3 mt-2"
                          >
                            <option value="Choose Region">Choose Region</option>
                            <option value="Dhaka, Bangladesh">
                              Dhaka, Bangladesh
                            </option>
                            <option value="Chittagong, Bangladesh">
                              Chittagong, Bangladesh
                            </option>
                            <option value="Khulna, Bangladesh">
                              Khulna, Bangladesh
                            </option>
                            <option value="Rajshahi, Bangladesh">
                              Rajshahi, Bangladesh
                            </option>
                            <option value="Sylhet, Bangladesh">
                              Sylhet, Bangladesh
                            </option>
                          </select>
                        </div>
                      </div>
                      <label>Postcode</label>
                      <input
                        type="text"
                        value={this.state.postcode}
                        required
                        onChange={(event) =>
                          this.setState({ postcode: event.target.value })
                        }
                        className="form-control mb-3"
                      />
                    </div>
                  </div>

                  <div className="profile">
                    <h3>
                      <FontAwesomeIcon
                        icon={faImage}
                        size="1x"
                        className="me-4"
                      ></FontAwesomeIcon>{" "}
                      Gallery
                    </h3>
                    <div className="lawyer-form">
                      <input
                        type="file"
                        required
                        onChange={(event) =>
                          this.setState({ picture: event.target.files[0] })
                        }
                        className="form-control form-control-lg mb-3"
                      />

                      {this.state.picture.type ? (
                        <img
                          src={URL.createObjectURL(this.state.picture)}
                          className="img-fluid my-3"
                          alt="gallery"
                          width="400px"
                        />
                      ) : (
                        <img
                          src={this.state.picture}
                          className="img-fluid my-3"
                          alt="gallery"
                          width="400px"
                        />
                      )}

                      <div className="info">
                        By selecting (clicking on a photo) one of the uploaded
                        photos you will set it as Featured Image for this
                        listing (marked by icon with star). Drag and drop
                        thumbnails to re-order images in gallery.
                      </div>
                    </div>
                  </div>

                  <div className="profile">
                    <h3>
                      <FontAwesomeIcon
                        icon={faPaste}
                        size="1x"
                        className="me-4"
                      ></FontAwesomeIcon>{" "}
                      Details
                    </h3>

                    <div className="lawyer-form">
                      <textarea
                        placeholder="Write a description about yourself"
                        required
                        value={this.state.description}
                        rows="12"
                        className="form-control mb-3"
                        maxLength={300}
                        onChange={(event) =>
                          this.setState({
                            description: event.target.value,
                          })
                        }
                      ></textarea>

                      <div className="text-end">
                        Remaining Characters: {this.state.description.length}
                        /300
                      </div>

                      <h4 className="my-3">Education</h4>
                      {this.state.education.map((edu, index) => (
                        <div className="row border py-3 mb-3" key={index}>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-3">Education {index + 1}</h5>
                            {index !== 0 ? (
                              <div
                                className="text-red"
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.handleEducationRemoveFields(index)
                                }
                              >
                                Remove
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              value={edu.name}
                              onChange={(event) =>
                                this.handleEducationInputChange(index, event)
                              }
                              className="form-control mb-3"
                              placeholder="Enter University Name"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Designation</label>
                            <input
                              type="text"
                              name="designation"
                              value={edu.designation}
                              onChange={(event) =>
                                this.handleEducationInputChange(index, event)
                              }
                              className="form-control mb-3"
                              placeholder="Enter Degree"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Start Date:</label>
                            <input
                              type="date"
                              name="start"
                              value={edu.start}
                              onChange={(event) =>
                                this.handleEducationInputChange(index, event)
                              }
                              className="form-control mb-3"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label>End Date:</label>
                            <input
                              type="date"
                              name="end"
                              value={edu.end}
                              onChange={(event) =>
                                this.handleEducationInputChange(index, event)
                              }
                              className="form-control mb-3"
                              required
                            />
                          </div>
                        </div>
                      ))}

                      {this.state.education.length <= 2 ? (
                        <div className="my-3 text-end">
                          <button
                            type="button"
                            className="button"
                            onClick={this.handleEducationAddFields}
                          >
                            Add Education
                          </button>
                        </div>
                      ) : null}

                      <h4 className="my-3">Experience</h4>
                      {this.state.experience.map((exp, index) => (
                        <div className="row border py-3 mb-3" key={index}>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-3">Experience {index + 1}</h5>
                            {index !== 0 ? (
                              <div
                                className="text-red"
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.handleExperienceRemoveFields(index)
                                }
                              >
                                Remove
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              value={exp.name}
                              onChange={(event) =>
                                this.handleExperienceInputChange(index, event)
                              }
                              className="form-control mb-3"
                              placeholder="Enter work experience"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Designation</label>
                            <input
                              type="text"
                              name="designation"
                              value={exp.designation}
                              onChange={(event) =>
                                this.handleExperienceInputChange(index, event)
                              }
                              className="form-control mb-3"
                              placeholder="Enter Designation"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Start Date:</label>
                            <input
                              type="date"
                              name="start"
                              value={exp.start}
                              onChange={(event) =>
                                this.handleExperienceInputChange(index, event)
                              }
                              className="form-control mb-3"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label>End Date:</label>
                            <input
                              type="date"
                              name="end"
                              value={exp.end}
                              onChange={(event) =>
                                this.handleExperienceInputChange(index, event)
                              }
                              className="form-control mb-3"
                              required
                            />
                          </div>
                        </div>
                      ))}

                      {this.state.experience.length <= 2 ? (
                        <div className="my-3 text-end">
                          <button
                            type="button"
                            className="button"
                            onClick={this.handleExperienceAddFields}
                          >
                            Add Experience
                          </button>
                        </div>
                      ) : null}

                      <h4 className="my-3">Certificate</h4>
                      {this.state.certificate.map((cert, index) => (
                        <div className="row border py-3 mb-3" key={index}>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-3">Certificate {index + 1}</h5>
                            {index !== 0 ? (
                              <div
                                className="text-red"
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.handleCertificateRemoveFields(index)
                                }
                              >
                                Remove
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-4">
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              value={cert.name}
                              onChange={(event) =>
                                this.handleCertificateInputChange(index, event)
                              }
                              className="form-control mb-3"
                              placeholder="Enter certificate name"
                              required
                            />
                          </div>
                          <div className="col-md-4">
                            <label>For</label>
                            <input
                              type="text"
                              name="for"
                              value={cert.for}
                              onChange={(event) =>
                                this.handleCertificateInputChange(index, event)
                              }
                              className="form-control mb-3"
                              placeholder="Certified for"
                              required
                            />
                          </div>
                          <div className="col-md-4">
                            <label>Start Date:</label>
                            <input
                              type="date"
                              name="at"
                              value={cert.at}
                              onChange={(event) =>
                                this.handleCertificateInputChange(index, event)
                              }
                              className="form-control mb-3"
                              required
                            />
                          </div>
                        </div>
                      ))}

                      {this.state.certificate.length <= 2 ? (
                        <div className="my-3 text-end">
                          <button
                            type="button"
                            className="button"
                            onClick={this.handleCertificateAddFields}
                          >
                            Add Certificate
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="profile">
                    <h3>
                      <FontAwesomeIcon
                        icon={faTag}
                        size="1x"
                        className="me-4"
                      ></FontAwesomeIcon>{" "}
                      Pricing
                    </h3>
                    <div className="lawyer-form">
                      <div className="info mb-3">
                        Provide the range of your pricing, indicating both the
                        minimum and maximum range to ensure flexibility in
                        budget considerations.
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label>Minimum price</label>
                          <input
                            type="number"
                            min="0"
                            className="form-control mb-3"
                            value={this.state.minimumPricing}
                            onChange={(event) =>
                              this.setState({
                                minimumPricing: event.target.value,
                              })
                            }
                            placeholder="BDT"
                            required
                          />
                        </div>

                        <div className="col-md-6">
                          <label>Maximum price</label>
                          <input
                            type="number"
                            min="0"
                            className="form-control mb-3"
                            value={this.state.maximumPricing}
                            onChange={(event) =>
                              this.setState({
                                maximumPricing: event.target.value,
                              })
                            }
                            placeholder="BDT"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile">
                    <h3 className="booking">
                      <div>
                        <FontAwesomeIcon
                          icon={faCalendarCheck}
                          size="1x"
                          className="me-4"
                        ></FontAwesomeIcon>{" "}
                        Booking
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.booking}
                          onChange={(event) =>
                            this.setState({ booking: event.target.checked })
                          }
                          role="switch"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </h3>

                    {this.state.booking ? (
                      <div className="lawyer-form">
                        <div className="info">
                          By turning on switch on the right, you'll enable
                          booking feature, it will add Booking widget on your
                          listing. You'll see more configuration settings below.
                        </div>

                        <p className="mt-4 text-center">Time Slots</p>
                        <div className="slots">
                          <DaySlots
                            day="Monday"
                            stateDay={this.state.slots.monday}
                            onTimeChange={(timeString) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  monday: {
                                    ...prevState.slots.monday,
                                    startTime: timeString[0],
                                    endTime: timeString[1],
                                  },
                                },
                              }))
                            }
                            onDurationChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  monday: {
                                    ...prevState.slots.monday,
                                    duration: value,
                                  },
                                },
                              }))
                            }
                            onIntervalChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  monday: {
                                    ...prevState.slots.monday,
                                    interval: value,
                                  },
                                },
                              }))
                            }
                          ></DaySlots>

                          <DaySlots
                            day="Tuesday"
                            stateDay={this.state.slots.tuesday}
                            onTimeChange={(timeString) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  tuesday: {
                                    ...prevState.slots.tuesday,
                                    startTime: timeString[0],
                                    endTime: timeString[1],
                                  },
                                },
                              }))
                            }
                            onDurationChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  tuesday: {
                                    ...prevState.slots.tuesday,
                                    duration: value,
                                  },
                                },
                              }))
                            }
                            onIntervalChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  tuesday: {
                                    ...prevState.slots.tuesday,
                                    interval: value,
                                  },
                                },
                              }))
                            }
                          ></DaySlots>

                          <DaySlots
                            day="Wednesday"
                            stateDay={this.state.slots.wednesday}
                            onTimeChange={(timeString) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  wednesday: {
                                    ...prevState.slots.wednesday,
                                    startTime: timeString[0],
                                    endTime: timeString[1],
                                  },
                                },
                              }))
                            }
                            onDurationChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  wednesday: {
                                    ...prevState.slots.wednesday,
                                    duration: value,
                                  },
                                },
                              }))
                            }
                            onIntervalChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  wednesday: {
                                    ...prevState.slots.wednesday,
                                    interval: value,
                                  },
                                },
                              }))
                            }
                          ></DaySlots>

                          <DaySlots
                            day="Thursday"
                            stateDay={this.state.slots.thursday}
                            onTimeChange={(timeString) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  thursday: {
                                    ...prevState.slots.thursday,
                                    startTime: timeString[0],
                                    endTime: timeString[1],
                                  },
                                },
                              }))
                            }
                            onDurationChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  thursday: {
                                    ...prevState.slots.thursday,
                                    duration: value,
                                  },
                                },
                              }))
                            }
                            onIntervalChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  thursday: {
                                    ...prevState.slots.thursday,
                                    interval: value,
                                  },
                                },
                              }))
                            }
                          ></DaySlots>

                          <DaySlots
                            day="Friday"
                            stateDay={this.state.slots.friday}
                            onTimeChange={(timeString) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  friday: {
                                    ...prevState.slots.friday,
                                    startTime: timeString[0],
                                    endTime: timeString[1],
                                  },
                                },
                              }))
                            }
                            onDurationChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  friday: {
                                    ...prevState.slots.friday,
                                    duration: value,
                                  },
                                },
                              }))
                            }
                            onIntervalChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  friday: {
                                    ...prevState.slots.friday,
                                    interval: value,
                                  },
                                },
                              }))
                            }
                          ></DaySlots>

                          <DaySlots
                            day="Saturday"
                            stateDay={this.state.slots.saturday}
                            onTimeChange={(timeString) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  saturday: {
                                    ...prevState.slots.saturday,
                                    startTime: timeString[0],
                                    endTime: timeString[1],
                                  },
                                },
                              }))
                            }
                            onDurationChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  saturday: {
                                    ...prevState.slots.saturday,
                                    duration: value,
                                  },
                                },
                              }))
                            }
                            onIntervalChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  saturday: {
                                    ...prevState.slots.saturday,
                                    interval: value,
                                  },
                                },
                              }))
                            }
                          ></DaySlots>

                          <DaySlots
                            day="Sunday"
                            stateDay={this.state.slots.sunday}
                            onTimeChange={(timeString) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  sunday: {
                                    ...prevState.slots.sunday,
                                    startTime: timeString[0],
                                    endTime: timeString[1],
                                  },
                                },
                              }))
                            }
                            onDurationChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  sunday: {
                                    ...prevState.slots.sunday,
                                    duration: value,
                                  },
                                },
                              }))
                            }
                            onIntervalChange={(value) =>
                              this.setState((prevState) => ({
                                slots: {
                                  ...prevState.slots,
                                  sunday: {
                                    ...prevState.slots.sunday,
                                    interval: value,
                                  },
                                },
                              }))
                            }
                          ></DaySlots>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {this.state.booking ? (
                    <div className="profile">
                      <h3>
                        <FontAwesomeIcon
                          icon={faCalendarCheck}
                          size="1x"
                          className="me-4"
                        ></FontAwesomeIcon>{" "}
                        Availability Calender
                      </h3>

                      <div className="lawyer-form">
                        <div className="info mb-3">
                          Click date in calendar to mark the day as unavailable.
                        </div>
                        <div className="available">
                          <DatePicker
                            defaultValue={this.state.defaultAvailableSlots}
                            multiple
                            required
                            maxTagCount="responsive"
                            size="large"
                            onChange={(date, dateString) =>
                              this.setState({ availableSlot: dateString })
                            }
                          ></DatePicker>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <button
                    className="button"
                    type="submit"
                    onClick={() => this.preview()}
                  >
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

class DaySlots extends Component {
  render() {
    return (
      <div className="days">
        <p>{this.props.day}</p>

        <TimePicker.RangePicker
          defaultValue={[
            this.props.stateDay.startTime === ""
              ? false
              : dayjs(this.props.stateDay.startTime, format),
            this.props.stateDay.endTime === ""
              ? false
              : dayjs(this.props.stateDay.endTime, format) || "",
          ]}
          format={format}
          onChange={(time, timeString) => this.props.onTimeChange(timeString)}
        ></TimePicker.RangePicker>

        <div className="mt-3 mb-2 slot">
          <div className="d-flex align-items-center g-2">
            <div className="ms-2">Duration: </div>
            <select
              className="form-control"
              defaultValue={this.props.stateDay.duration}
              required
              onChange={(event) =>
                this.props.onDurationChange(event.target.value)
              }
            >
              <option value="30">30 mins</option>
              <option value="45">45 mins</option>
              <option value="60">1 hour</option>
              <option value="75">1 hour 15 mins</option>
              <option value="90">1 hour 30 mins</option>
              <option value="105">1 hour 45 mins</option>
              <option value="120">2 hour</option>
            </select>
          </div>
        </div>

        <div className="mt-2 mb-3 slot">
          <div className="d-flex align-items-center g-2">
            <div className="ms-2">Interval: </div>
            <select
              className="form-control"
              defaultValue={this.props.stateDay.interval}
              required
              onChange={(event) =>
                this.props.onIntervalChange(event.target.value)
              }
            >
              <option value="5">5 mins</option>
              <option value="10">10 mins</option>
              <option value="15">15 mins</option>
              <option value="20">20 mins</option>
              <option value="25">25 mins</option>
              <option value="30">30 mins</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}

export default LawyerProfile;
