import React, { Component } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faTag,
  faLocationArrow,
  faClock,
  faCalendar,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";

import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";

import Spinner from "../../components/spinner";

import "./find.scss";

let categories = [
  { value: "ACCIDENTS & INJURIES", label: "ACCIDENTS & INJURIES" },
  {
    value: "ADR. Arbitration & Mediation",
    label: "ADR. Arbitration & Mediation",
  },
  { value: "BANKRUPTCY & DEBT", label: "BANKRUPTCY & DEBT" },
  { value: "BUSINESS LAW", label: "BUSINESS LAW" },
  { value: "CIVIL & HUMAN RIGHTS", label: "CIVIL & HUMAN RIGHTS" },
  { value: "CONSUMER RIGHTS", label: "CONSUMER RIGHTS" },
  { value: "CRIMINAL DEFENSE", label: "CRIMINAL DEFENSE" },
  { value: "DISABILITY", label: "DISABILITY" },
  { value: "DIVORCE & FAMILY LAW", label: "DIVORCE & FAMILY LAW" },
  { value: "EMPLOYEES' RIGHTS", label: "EMPLOYEES' RIGHTS" },
  { value: "ESTATE PLANNING", label: "ESTATE PLANNING" },
  { value: "HEALTH CARE", label: "HEALTH CARE" },
  { value: "IMMIGRATION", label: "IMMIGRATION" },
  { value: "INSURANCE", label: "INSURANCE" },
  { value: "INTELLECTUAL PROPERTY", label: "INTELLECTUAL PROPERTY" },
  { value: "REAL ESTATE", label: "REAL ESTATE" },
];

export class FindLawyers extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      user: jwtDecode(localStorage.getItem("token")),
      lawyer: "",
      category: [],
      location: "Choose Location",
    };
  }

  async componentDidMount() {
    if (this.state.user.package === "Free")
      return (window.location.href = "/packages");

    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/lawyer/`,
        config
      );

      console.log(data.lawyer);

      if (data.success) this.setState({ loading: false, lawyer: data.lawyer });
      console.log(this.state.lawyer);
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  }

  async setBookmark(id) {
    try {
      let config = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      let { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/bookmark`,
        { id },
        config
      );

      if (data.success) alert("Added to your bookmark");
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="lawyers">
        <Navbar></Navbar>
        <div className="container my-5">
          <h1>Lawyers</h1>
          <p>Browse Lawyers</p>

          <div className="lawyers-list">
            <div className="row mt-5">
              <div className="col-md-3">
                <h3 className="mb-4">Filters</h3>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Search Lawyers"
                />
                <select
                  value={this.state.location}
                  required
                  onChange={(event) =>
                    this.setState({ location: event.target.value })
                  }
                  className="form-control mb-3 mt-2"
                >
                  <option value="Choose Region">Choose Location</option>
                  <option value="Dhaka, Bangladesh">Dhaka, Bangladesh</option>
                  <option value="Chittagong, Bangladesh">
                    Chittagong, Bangladesh
                  </option>
                  <option value="Khulna, Bangladesh">Khulna, Bangladesh</option>
                  <option value="Rajshahi, Bangladesh">
                    Rajshahi, Bangladesh
                  </option>
                  <option value="Sylhet, Bangladesh">Sylhet, Bangladesh</option>
                </select>
                <Select
                  options={categories}
                  defaultValue={this.state.category}
                  required
                  isMulti
                  placeholder="Select Categories"
                  onChange={(value) => this.setState({ category: value })}
                  className="mb-3"
                />
                <div className="d-grid">
                  <button className="button">Search</button>
                </div>
              </div>
              <div className="col-md-9">
                {this.state.loading ? (
                  <Spinner></Spinner>
                ) : (
                  this.state.lawyer.map((profile, index) => (
                    <div className="lawyer-profile" key={index}>
                      <div className="row">
                        <div className="col-md-8 col-sm-12">
                          <div className="d-flex flex-row gap-4">
                            <img
                              className="img-fluid"
                              src={profile.picture}
                              alt={profile.name}
                            ></img>
                            <div className="lawyer-info">
                              <h4 className="d-flex align-items-start">
                                {profile.name}
                                <span className="verified ms-2">
                                  {profile.verified ? (
                                    <FontAwesomeIcon
                                      icon={faCircleCheck}
                                      size="xs"
                                    ></FontAwesomeIcon>
                                  ) : null}
                                </span>
                              </h4>
                              {profile.category.map((cat, index) => (
                                <h7 className="category" key={index}>
                                  {cat.label}
                                </h7>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 lawyer-details">
                          <div>
                            <div className="d-flex align-items-center gap-3 mb-3">
                              <FontAwesomeIcon
                                icon={faLocationArrow}
                                size="lg"
                                className="color-"
                              />
                              <div className="details">
                                {profile.location.region}
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-3 mb-3">
                              <FontAwesomeIcon icon={faClock} size="lg" />
                              <div className="details">
                                <span
                                  className={
                                    new Date().getDay() === 1 ? "bold" : ""
                                  }
                                >
                                  Mon
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 2 ? "bold" : ""
                                  }
                                >
                                  Tue
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 3 ? "bold" : ""
                                  }
                                >
                                  Wed
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 4 ? "bold" : ""
                                  }
                                >
                                  Thu
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 5 ? "bold" : ""
                                  }
                                >
                                  Fri
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 6 ? "bold" : ""
                                  }
                                >
                                  Sat
                                </span>
                                ,
                                <span
                                  className={
                                    new Date().getDay() === 0 ? "bold" : ""
                                  }
                                >
                                  Sun
                                </span>
                                ,
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-3 mb-3">
                              <FontAwesomeIcon icon={faTag} size="lg" />
                              <div className="details">
                                Starting from{" "}
                                <span className="bold">
                                  &#2547; {profile.pricing[0]}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-3 mb-3">
                              <FontAwesomeIcon icon={faCalendar} size="lg" />
                              <div className="details available">
                                Available Today
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-row gap-2 items-center">
                            <button
                              className="button"
                              onClick={() =>
                                (window.location.href = `/lawyer/${profile._id}`)
                              }
                            >
                              View Full Profile
                            </button>
                            <button
                              className="button w-25"
                              onClick={() => this.setBookmark(profile._id)}
                            >
                              <FontAwesomeIcon icon={faHeart} size="lg" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default FindLawyers;
